import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import SelectInput from "../../theme/components/input/select-input";
import { getAll } from "../../core/utils/common-function";
import Pagination from "../../theme/components/pagination/pagination";
import { MENU_NAMES } from "../../core/utils/constants";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextArea from "../../theme/components/input/text-area";
import { useLocation, useNavigate } from "react-router-dom";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import api from "../../core/api";
import { SuccessAlert } from "../../core/utils/common-sweet-alert";
import { ROUTE_LINKS } from "../../core/utils/route-links";

const AddPayment = () => {
  const [bank, setBank] = useState([]);
  const params = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const [initialState, setInitialState] = useState({
    immigrationFileId: params.state.immigrationFileId,
    date: "",
    guardianName: "",
    phoneNumber: "",
    address: "",
    emailId: "",
    mode: "",
    bankId: "",
    checkNumber: "",
    accountNumber: "",
    deposit: "",
    withdrawal: "",
    remark: "",
    advance: "",
  });
  const getAllPromises = () => {
    const getBankRequest = getAll("Bank", payload);
    Promise.all([getBankRequest])
      .then((res) => {
        const [bankResponse, modeResponse] = res;
        const Bank = bankResponse.data.data.map((bank) => ({
          text: bank.name,
          value: bank.bankId,
        }));
        setBank(Bank);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const validationSchema = yup.object({
    bankId: SCHEMA_VALIDATOR.GUID_VALIDATOR,
    phoneNumber: SCHEMA_VALIDATOR.PHONE_NUMBER_VALIDATOR,
    remark:SCHEMA_VALIDATOR.SPECIAL_CHARECTERS_VALIDATOR,
  });
  useEffect(() => {
    document.title = "Add Payment | Digital Filing";
    getAllPromises();
  }, []);
  return (
    <Container maxW="container.xll" padding={"20px"}>
      <Box>
        <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.ADD_PAYMENT)} />
      </Box>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={initialState}
        onSubmit={async (values) => {
          const finalPayload = {
            // BranchId: values.isAdmin ? null : selector.branch.userBranchId,
            immigrationFileId: params.state.immigrationFileId,
            date: values.date,
            guardianName: values.guardianName,
            phoneNumber: values.phoneNumber,
            address: values.address,
            mode: values.mode,
            emailId: values.emailId,
            bankId: values.bankId,
            accountNumber: values.accountNumber,
            checkNumber: values.checkNumber,
            deposit: values.deposit,
            withdrawal: values.withdrawal,
            remark: values.remark,
            advance: values.advance,
          };
          api
            .post(`/ImmigrationFile/create-fund`, { ...finalPayload })
            .then((res) => {
              navigate(ROUTE_LINKS[MENU_NAMES.VIEW_PAYMENT], {
                state: {
                  id: params.state.immigrationFileId,
                },
              });
              SuccessAlert("Record added Successfully");
            })
            .catch((err) => {
              console.log(err, "err");
            });
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form
            noValidate={true}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box>
                <Heading variant={"h1"} marginBottom={"20px"}>
                  Add Payment
                </Heading>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                marginBottom={"20px"}
              >
                <Button
                  variant={"warning"}
                  onClick={() => {
                    navigate(ROUTE_LINKS[MENU_NAMES.VIEW_PAYMENT], {
                      state: {
                        immigrationFileId: params.state.immigrationFileId,
                      },
                    });
                  }}
                >
                  Discard
                </Button>
                <Button variant={"primary"} type="submit">
                  Submit
                </Button>
              </Box>
            </Box>
            <Box
              display={"flex"}
              columnGapgap={"20px"}
              borderRadius={"10px"}
              flexDirection={"column"}
            >
              <Box
                padding={"20px"}
                borderRadius={"10px"}
                border={"1px solid #e6e6e6"}
              >
                <Box spacingX="30px" spacingY="0px" display={"flex"} w={"100%"}>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "2", lg: "4" }}
                    width={"100%"}
                    columnGap={"20px"}
                    borderRadius={"10px"}
                  >
                    <TextInput
                      type="date"
                      name="createdOn"
                      isRequired={false}
                      onChange={handleChange}
                      placeholder={""}
                      variant={errors.createdOn ? "errored" : "flushed"}
                      label={{
                        text: "Deposit On",
                        colors: errors.createdOn ? "red !important" : "",
                        variant: errors.createdOn ? "errored" : "",
                      }}
                      isReadOnly={isLoading}
                    />
                    <TextInput
                      type="text"
                      name="guardianName"
                      isRequired={false}
                      value={values.guardianName}
                      label={{
                        text: "Guardian",
                        colors: errors.guardianName ? "red !important" : "",
                        variant: errors.guardianName ? "errored" : "",
                      }}
                      onChange={handleChange}
                      placeholder={""}
                      variant={errors.guardianName ? "errored" : "flushed"}
                      isReadOnly={isLoading}
                    />
                    <TextInput
                      type="tel"
                      isRequired={true}
                      name="phoneNumber"
                      value={values.phoneNumber}
                      label={{
                        text: "Phone Number",
                        colors: errors.phoneNumber ? "red !important" : "",
                        variant: errors.phoneNumber ? "errored" : "",
                      }}
                      onChange={handleChange}
                      placeholder={""}
                      variant={errors.phoneNumber ? "errored" : "flushed"}
                      isReadOnly={isLoading}
                    />
                    <TextInput
                      type="text"
                      name="address"
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.address}
                      variant={errors.address ? "errored" : "flushed"}
                      label={{
                        text: "Address",
                        colors: errors.address ? "red !important" : "",
                        variant: errors.address ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                    <TextInput
                      type="text"
                      name="emailId"
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.emailId}
                      variant={errors.emailId ? "errored" : "flushed"}
                      label={{
                        text: "Email",
                        colors: errors.emailId ? "red !important" : "",
                        variant: errors.emailId ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                    <SelectInput
                      formControl={{
                        id: "bankId",
                        variant: "floating",
                      }}
                      name="bankId"
                      isReadOnly={isLoading}
                      isRequired={true}
                      value={values.bankId}
                      label={{
                        text: "Bank",
                        colors: errors.bankId ? "red !important" : "",
                        variant: errors.bankId ? "errored" : "",
                      }}
                      onChange={handleChange}
                      options={bank}
                      variant={errors.bankId ? "errored" : "flushed"}
                    />
                    <TextInput
                      type="text"
                      name="accountNumber"
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.accountNumber}
                      variant={errors.accountNumber ? "errored" : "flushed"}
                      label={{
                        text: "Account Number",
                        colors: errors.accountNumber ? "red !important" : "",
                        variant: errors.accountNumber ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                    <TextInput
                      type="text"
                      name="checkNumber"
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.checkNumber}
                      variant={errors.checkNumber ? "errored" : "flushed"}
                      label={{
                        text: "Check Number",
                        colors: errors.checkNumber ? "red !important" : "",
                        variant: errors.checkNumber ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                    <TextInput
                      type="text"
                      name="mode"
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.mode}
                      variant={errors.mode ? "errored" : "flushed"}
                      label={{
                        text: "Mode",
                        colors: errors.mode ? "red !important" : "",
                        variant: errors.mode ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                    <TextInput
                      type="text"
                      name="advance"
                      placeholder={""}
                      isRequired={true}
                      isReadOnly={isLoading}
                      value={values.advance}
                      variant={errors.advance ? "errored" : "flushed"}
                      label={{
                        text: "Advance",
                        colors: errors.advance ? "red !important" : "",
                        variant: errors.advance ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                    <TextInput
                      type="text"
                      name="deposit"
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.deposit}
                      variant={errors.deposit ? "errored" : "flushed"}
                      label={{
                        text: "Deposit",
                        colors: errors.deposit ? "red !important" : "",
                        variant: errors.deposit ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                    <TextInput
                      type="text"
                      name="withdrawal"
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.withdrawal}
                      variant={errors.withdrawal ? "errored" : "flushed"}
                      label={{
                        text: "Withdrawal",
                        colors: errors.withdrawal ? "red !important" : "",
                        variant: errors.withdrawal ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                    <TextArea
                      name="remark"
                      isReadOnly={isLoading}
                      isRequired={false}
                      value={values.remark}
                      placeholder={""}
                      label={{
                        text: "Remarks",
                        colors: errors.remark ? "red !important" : "",
                        variant: errors.remark ? "errored" : "",
                      }}
                      onChange={handleChange}
                      variant={errors.remark ? "errored" : "flushed"}
                    />
                  </SimpleGrid>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AddPayment;
