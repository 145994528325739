import React, { useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import TeleCallerDashboard from "./tele-caller";
import AdminDashboard from "./admin";
import { ROLES } from "../../core/utils/constants";

const Dashboard = (props) => {
  const selector = useSelector((_) => _);

  useEffect(() => {
    document.title = "Dashboard | Digital Filing";
  }, []);

  if (selector.auth.login.RoleId == ROLES.TELE_CALLER) {
    return <TeleCallerDashboard permission={props} />;
  } else {
    return <AdminDashboard permission={props} />;
  }
};

export default Dashboard;
