import React, { useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Collapse,
  Text,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import Grid from "../../theme/components/grid/grid";
import Pagination from "../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { MENU_NAMES, MODULE_ENUMS, PAGE_SIZE, ROLES } from "../../core/utils/constants";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import SelectInput from "../../theme/components/input/select-input";
import { GoFilter } from "react-icons/go";
import useTableHeightFix from "../../core/hooks/useTableHeightFix";
import api from "../../core/api";
import {
  CREATE_RESPONSE,
  getFilterCount,
  createFilterPayload,
  handleFilterChange,
  handleResetState,
  getLastActiveFilter,
  handleActiveFilter,
  createFilterState,
} from "../../core/utils/common-function";
import { useSelector } from "react-redux";

const Role = (props) => {
  const {moduleId} = props;
  const selector = useSelector((_) => _);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR || 
selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [show, setShow] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
  });
  const [activeFilter, setActiveFilter] = useState(0);
  const [filterState, setFilterState] = useState({
    name: "",
    isActive: null,
  });
  const [columns, setColumns] = useState(
    GRID_COLUMNS.ROLE.filter((x) => x.Active && x.Visibility)
  );
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);

  const handleToggle = () => {
    setShow(!show);
  };

  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };

  const getAllRecords = () => {
    api
      .post("/Role/getAll", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.ROLE, res.data.data.data));
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleActiveFilter('delete',moduleId)
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
  };


  const lastActiveFilter = async ()=>{
    const lastFilter = await handleActiveFilter('get', moduleId);
    if (lastFilter) {
      const filledFilter = JSON.parse(lastFilter.data.data);
      if (filledFilter) {
        setAPIPayload({
          ...apiPayload,
          filter: filledFilter,
          pageSize: PAGE_SIZE,
          pageIndex: 1,
        });
        createFilterState(filledFilter, setFilterState, setActiveFilter);
      }
    }
  };

  useEffect(()=>{
    lastActiveFilter()
  },[])


  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    document.title = "Role & Permission | Digital Filing";
    getAllRecords();
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [apiPayload]);

  return (
    <Box>
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.ROLE)} />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>Role & Permission</Heading>
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"0px"}
                height={"30px"}
                width={"30px"}
                lineHeight={"30px"}
              >
                {activeFilter}
              </Box>
            </Button>
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(filterState);
                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });
                const jsonString = JSON.stringify(payloadArr);
                handleActiveFilter('post','',{moduleId:moduleId, StateJson:jsonString  })
              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <TextInput
                  type="text"
                  placeholder={""}
                  name="Name"
                  value={filterState.Name?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Name")
                  }
                  marginTop="20px"
                  formControl={{ id: "name" }}
                  label={{ text: "Name" }}
                />
                <SelectInput
                  formControl={{ id: "IsActive", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.IsActive?.value}
                  name="IsActive"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Status")
                  }
                  label={{
                    text: "Status",
                  }}
                  options={[
                    { text: "Active", value: true },
                    { text: "Inactive", value: false },
                  ]}
                />
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>

        <Grid
          name={"role"}
          columns={GRID_COLUMNS.ROLE}
          aColumns={columns}
          data={data}
          permission={props}
          hasDelete={false}
          hasExport={hasExport}
          setColumns={(e) => setColumns(e)}
          viewRoute={ROUTE_LINKS[MENU_NAMES.VIEW_ROLE]}
          editRoute={ROUTE_LINKS[MENU_NAMES.EDIT_ROLE]}
        />
        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) =>
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
    </Box>
  );
};
export default Role;
