// Redux keys
export const LAYOUT_TOGGLE = 'LAYOUT_TOGGLE';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const GET_SINGLE_RESPONSE = 'GET_SINGLE_RESPONSE';
export const REQUIRED_RESPONSE = 'REQUIRED_RESPONSE';
export const GET_LOGIN_USER_DATA = 'GET_LOGIN_USER_DATA';
export const USER_AUTH_TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refresh-token';
export const BRANCH_ID = 'SET_BRANCH_ID';
export const GET_BRANCH_ID = 'GET_BRANCH_ID';
export const BRANCH_NAME = 'SET_BRANCH_NAME';
export const BRANCHES = 'SET_BRANCHES';
export const REBIND_BRANCHES = 'REBIND_BRANCHES';
export const PAGE_SIZE = 50;
export const EXPORT_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXPORT_FILE_EXTENSION = '.xlsx';
export const PENDING_DOCUMENTS = 'PENDING_DOCUMENTS';
export const ROUTE_PERMISSIONS = 'ROUTE_PERMISSIONS';
export const ROLES = {
  SYSTEM_ADMINISTRATOR: 1,
  ACCOUNTANT: 2,
  BRANCH_HEAD: 3,
  COUNSELLOR: 4,
  TELE_CALLER: 5,
  FILLING_HEAD: 6,
  ADMINISTRATOR: 7,
  FOLLOWUP_PERSONNEL: 8,
};
export const ENQUIRY_STATUS = {
  CLOSED: 1,
  CONVERTED_TO_FILE: 2,
  INTERESTED: 3,
  LOST: 4,
  NEUTRAL: 5,
  NOT_ELIGIBLE: 6,
  NOT_INTERESTED: 7,
  PHONE_NOT_PICKED: 8,
  PHONE_SWITCH_OFF: 9,
};
export const CASE_STATUS = {
  ACTIVE: 1,
  CLOSED: 2,
  FILE_ON_HOLD: 3,
  VISA_GRANTED: 4,
  VISA_REFUSED: 5,
  IN_EMBASSY: 6,
};
export const FILTER_ENUMS = {
  Equals: 0,
  NotEqual: 1,
  GreaterThan: 2,
  LessThan: 3,
  GreaterThanOrEqual: 4,
  LessThanOrEqual: 5,
  Contains: 6,
  StartsWith: 7,
  EndsWith: 8,
};

// Layout Keys
export const MENU_NAMES = {
  LANDING: 'Landing',
  DASHBOARD: 'Dashboard',
  DAILY_ROUTINE: 'Daily Routine',
  DAILY_FOLLOWUP: 'Daily Followup',
  PROFILE: 'Profile',
  ENQUIRY: 'Enquiry',
  ADD_ENQUIRY: 'Add Enquiry',
  EDIT_ENQUIRY: 'Edit Enquiry',
  VIEW_ENQUIRY: 'View Enquiry',
  EDIT_VIEW_ENQUIRY: 'Edit View Enquiry',
  FEEDBACK_REPORT: 'Feedback Report',
  ENQUIRY_STATUS: 'Enquiry Status',
  BOARD: 'Board',
  QUAL_STREAM: 'Qual/Stream',
  REFERENCE: 'Reference',
  REFERENCE_TYPE: 'Reference Type',
  IMMIGRATION: 'Immigration',
  ADD_IMMIGRATION: 'add-immigration',
  EDIT_IMMIGRATION: 'Edit Immigration',
  VIEW_IMMIGRATION: 'View Immigration',
  VISA_TYPE: 'Visa Type',
  VISA_COUNTRY: 'Visa Country',
  VISA_CITY: 'Visa City',
  VISA_COLLEGE: 'Visa College',
  VISA_COURSE: 'Visa Course',
  FILE_TYPE: 'File Type',
  FILE_STATUS: 'File Status',
  FILE_MODE: 'File Mode',
  FILE_DOCUMENT: 'File Document',
  BRANCH: 'Branch',
  ADD_BRANCH: 'Add Branch',
  EDIT_BRANCH: 'Edit Branch',
  VIEW_BRANCH: 'View Branch',
  PENDING_WORK: 'Pending Work',
  PENDING_DOCUMENT: 'Pending Document',
  ACCOUNT: 'Account',
  FUND_TYPE: 'Fund Type',
  GRADE: 'Grade',
  BANK: 'Bank',
  GENERAL: 'General',
  GENERAL_USER: 'General User',
  USER: 'User',
  ADD_USER: 'Add User',
  EDIT_USER: 'Edit User',
  VIEW_USER: 'View User',
  ROLE: 'Role',
  GENERAL_ROLE: 'General Role',
  ADD_ROLE: 'Add Role',
  EDIT_ROLE: 'Edit Role',
  VIEW_ROLE: 'View Role',
  MODULE: 'Module',
  STATE: 'State',
  DISTRICT: 'District',
  NOTFOUND: 'Not-Found',
  UN_AUTHORIZED: 'un-authorized',
  ERROR_BOUNDARY: 'error-boundary',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  VIEW_PAYMENT: 'View payment',
  ADD_PAYMENT: 'Add Payment',
  VIEW_PERMISSIONS: 'View Permission',
};

export const ERROR_CODES = {
  CONTINUE: 100,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  UNSUPPORTED_MEDIA_TYPE: 415,
  TO_MANY_REQUESTES: 429,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  INSUFICENT_STORAGE: 507,
};

export const REGEX = {
  ALPHANUMERIC: '^[A-Za-z0-9,# ]*$',
  PASSWORD: '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*?&#])[0-9A-Za-z@$!%*?&#]{8,12}$',
  ONLY_NUMBERS: '^[0-9]+$',
  ONLY_ALPHABETS: '^[A-Za-z]+( [A-Za-z]+)*$',
  GUID: '^[A-Za-z0-9-]*$',
  EMAIL: '^[A-Za-z0-9+_.-]+@(.+)$',
  PHONE_NUMBER: '^[0-9]{10}$',
  STRING_VALIDATOR: '^[a-zA-Z0-9 ]+$',
  ALPHANUMERIC_SPECIAL_CHARACTER: '^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*(?:, [a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*|-[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*)*$',
  SPECIAL_REMARK_CHARACTER: '^[a-zA-Z0-9,-.:() \n]*$',
};

export const INTAKE = [
  { text: 'Jan', value: '1' },
  { text: 'Feb', value: '2' },
  { text: 'March', value: '3' },
  { text: 'April', value: '4' },
  { text: 'May', value: '5' },
  { text: 'June', value: '6' },
  { text: 'July', value: '7' },
  { text: 'August', value: '8' },
  { text: 'Sep', value: '9' },
  { text: 'Oct', value: '10' },
  { text: 'Nov', value: '11' },
  { text: 'Dec', value: '12' },
];

export const FILE_TYPE = [
  { text: 'Normal', value: '1' },
  { text: 'Urgent', value: '2' },
];

export const ADMIN_ALLOWED_DOCUMENT = [
  { id: '81d84151-adcf-4607-b319-fe295379a3ba', text: 'Admission' },
  {
    id: 'e5476d4e-7f3a-4df4-b085-1b79e17db81c',
    text: 'Advance Paid',
  },
  {
    id: 'f9f0d31e-3adc-46bd-aab5-91f793dffec1',
    text: 'Bio Appointment',
  },
  {
    id: 'ed6f2ead-1a16-4828-9bed-2fee063d3f21',
    text: 'Branch Advance',
  },
  {
    id: '13ffed21-b258-4f07-992f-b1fbb8e1ecd1',
    text: 'COE/LOA/CAS',
  },
  {
    id: 'b5dd584b-c4e9-44e7-8ddc-e4b05573b906',
    text: 'Conditional Offer Letter',
  },
  {
    id: 'da39991d-c840-46d0-82ad-bfb91d3a1c69',
    text: 'Offer Letter',
  },
  {
    id: '5bbd3b1b-253c-4272-ac1f-369ab41f8033',
    text: 'GTE Clear',
  },
  {
    id: '663cc7d3-9c81-4604-91b1-e1ac7a3977c7',
    text: 'Embassy Fees',
  },
  {
    id: '8eed8e6a-5c69-4084-baac-87731f656781',
    text: 'EMB. Fee Refund OR Adjust',
  },
  {
    id: '65058dd6-4fea-4177-bc20-4ceac9ee1dc0',
    text: 'Feedback (Head Office)',
  },
  {
    id: '16661caf-bd21-4f57-95b7-0c1627615c2a',
    text: 'Fund Request',
  },
  {
    id: 'b5a5e1dd-39c5-4fa0-91ce-6f12fe667a3f',
    text: 'Funds',
  },
  {
    id: '8f2a38d3-02a6-47a0-971b-589eb263abd2',
    text: 'Transfer from H&S',
  },
  {
    id: '18b4610f-e7c0-42e7-826b-20b7eff2990a',
    text: 'TT Received',
  },
  {
    id: '78217cba-a229-4d42-a111-43598d020506',
    text: 'Embassy Submission',
  },
  {
    id: 'b587362a-5dd1-4264-8162-7dbd2eb811fc',
    text: 'File Check',
  },
  {
    id: 'aa7b2772-bbdc-4d78-8e52-ecec1a9317ee',
    text: 'Extra',
  },
  {
    id: '46d478ba-9bf4-49b1-b9b1-adbd7c9697d4',
    text: 'Request',
  },
  {
    id: '0cf34412-7ba1-448b-be34-8d3e34794a97',
    text: 'SOP Check',
  },
  {
    id: '475355e6-55e7-465b-9bbe-f03759cefb2f',
    text: 'Docs For Admission',
  },
  {
    id: 'ee315bf6-7fce-4c78-9ec2-3e5deca1b531',
    text: 'Orientation',
  },
  {
    id: 'fa4525e3-a181-4ba0-9d3b-08bdd841ac9b',
    text: 'Ready To Lodge',
  },
  {
    id: '320b1ac1-0654-41ca-93a4-a1624469e43f',
    text: 'Relodge Option',
  },
  {
    id: 'fc9ecd0b-4cde-4a7b-b353-31c497ccac0a',
    text: 'Result',
  },
  {
    id: '2d7b167f-01e1-4ce8-a6cd-43c01ed63840',
    text: 'Sticker',
  },
];

