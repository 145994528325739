import { MENU_NAMES } from "./constants";

const PARENT_ROUTE_LINKS = {
    [MENU_NAMES.LANDING] : '/',
    [MENU_NAMES.DASHBOARD] : '/dashboard',
    [MENU_NAMES.DAILY_ROUTINE] : '/daily-routine',
    [MENU_NAMES.DAILY_FOLLOWUP]:"/daily-followup",
    [MENU_NAMES.ACCOUNT] : '/account',
    [MENU_NAMES.FORGOT_PASSWORD] : '/forgot-password',
    [MENU_NAMES.RESET_PASSWORD] : '/reset-password',
    [MENU_NAMES.BRANCH] : '/branch',
    [MENU_NAMES.ENQUIRY] : '/enquiry',
    [MENU_NAMES.GENERAL] : '/general',
    [MENU_NAMES.IMMIGRATION] : '/immigration',
    [MENU_NAMES.PROFILE] : '/profile',
    [MENU_NAMES.USER] : '/user',
    [MENU_NAMES.ROLE] : '/role',
    [MENU_NAMES.NOTFOUND]:"/404",
    [MENU_NAMES.UN_AUTHORIZED]:"/un-authorized",
    [MENU_NAMES.ERROR_BOUNDARY]:"/error-boundary",
    
};
const CHILDREN_ROUTE_LINKS = {
  [MENU_NAMES.BANK]: `${PARENT_ROUTE_LINKS[MENU_NAMES.ACCOUNT]}/bank`,
  [MENU_NAMES.FUND_TYPE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.ACCOUNT]}/fund-type`,
  [MENU_NAMES.GRADE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.ACCOUNT]}/grade`,

  [MENU_NAMES.ADD_BRANCH]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.BRANCH]
  }/add-branch`,
  [MENU_NAMES.VIEW_PERMISSIONS]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.BRANCH]
  }/view-permission`,

  [MENU_NAMES.ADD_ENQUIRY]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]
  }/add-enquiry`,
  [MENU_NAMES.EDIT_VIEW_ENQUIRY]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]
  }/edit-view-enquiry`,
  [MENU_NAMES.EDIT_ENQUIRY]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]
  }/edit-enquiry`,
  [MENU_NAMES.VIEW_ENQUIRY]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]
  }/view-enquiry`,
  [MENU_NAMES.BOARD]: `${PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]}/board`,
  [MENU_NAMES.ENQUIRY_STATUS]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]
  }/enquiry-status`,
  [MENU_NAMES.FEEDBACK_REPORT]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]
  }/feedback-report`,
  [MENU_NAMES.QUAL_STREAM]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]
  }/qual-stream`,
  [MENU_NAMES.REFERENCE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]}/reference`,
  [MENU_NAMES.REFERENCE_TYPE]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.ENQUIRY]
  }/reference-type`,

  [MENU_NAMES.DISTRICT]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}/district`,
  [MENU_NAMES.STATE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}/state`,
  [MENU_NAMES.MODULE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}/module`,

  [MENU_NAMES.GENERAL_ROLE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}/role`,
  [MENU_NAMES.ADD_ROLE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}${
    PARENT_ROUTE_LINKS[MENU_NAMES.ROLE]
  }/add-role`,
  [MENU_NAMES.VIEW_ROLE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}${
    PARENT_ROUTE_LINKS[MENU_NAMES.ROLE]
  }/view-role`,
  [MENU_NAMES.EDIT_ROLE]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}${
    PARENT_ROUTE_LINKS[MENU_NAMES.ROLE]
  }/edit-role`,

  [MENU_NAMES.GENERAL_USER]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}/user`,
  [MENU_NAMES.ADD_USER]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}${
    PARENT_ROUTE_LINKS[MENU_NAMES.USER]
  }/add-user`,
  [MENU_NAMES.EDIT_USER]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}${
    PARENT_ROUTE_LINKS[MENU_NAMES.USER]
  }/edit-user`,
  [MENU_NAMES.VIEW_USER]: `${PARENT_ROUTE_LINKS[MENU_NAMES.GENERAL]}${
    PARENT_ROUTE_LINKS[MENU_NAMES.USER]
  }/view-user`,

  [MENU_NAMES.FILE_STATUS]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/file-status`,

  [MENU_NAMES.FILE_TYPE]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/file-type`,
  [MENU_NAMES.FILE_MODE]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/file-mode`,
  [MENU_NAMES.FILE_DOCUMENT]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/file-document`,
  [MENU_NAMES.VISA_CITY]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/visa-city`,
  [MENU_NAMES.PENDING_DOCUMENT]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/pending-document`,
  [MENU_NAMES.PENDING_WORK]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/pending-work`,
  [MENU_NAMES.VISA_TYPE]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/visa-type`,
  [MENU_NAMES.VISA_COLLEGE]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/visa-college`,
  [MENU_NAMES.VISA_COUNTRY]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/visa-country`,
  [MENU_NAMES.VISA_COURSE]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/visa-course`,
  [MENU_NAMES.EDIT_IMMIGRATION]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/edit-immigration`,
  [MENU_NAMES.ADD_IMMIGRATION]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/add-immigration`,
  [MENU_NAMES.VIEW_IMMIGRATION]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/view-immigration`,
  [MENU_NAMES.VIEW_PAYMENT]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/view-payment`,
  [MENU_NAMES.ADD_PAYMENT]: `${
    PARENT_ROUTE_LINKS[MENU_NAMES.IMMIGRATION]
  }/add-payment`,
};
export const ROUTE_LINKS = { ...PARENT_ROUTE_LINKS, ...CHILDREN_ROUTE_LINKS };
