import { FormControl, Box, Button, WrapItem, CircularProgress, Link } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import { Image, Heading, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import TextInput from '../../theme/components/input/text-input';
import { ErrorMessage, Form, Formik } from 'formik';
import * as yup from 'yup';
import { colors } from '../../theme/styles/colors';
import { ROUTE_LINKS } from '../../core/utils/route-links';
import { ERROR_CODES, MENU_NAMES, REFRESH_TOKEN_KEY, ROLES, SIGN_IN, USER_AUTH_TOKEN_KEY } from '../../core/utils/constants';
import { useDispatch } from 'react-redux';
import { userSignIn } from '../../core/store/actions';
import { dummyToken, PARSE_JWT } from '../../core/utils/common-function';
import SCHEMA_VALIDATOR from '../../core/utils/schema-validator';
import { useEffect } from 'react';
import axios from 'axios';
import { WarningAlert } from '../../core/utils/common-sweet-alert';

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [initialState, setInitialState] = useState({
    emailID: '',
    password: '',
    decoded: {},
  });
  const { STRING_VALIDATOR, PASSWORD_VALIDATOR } = SCHEMA_VALIDATOR;

  const validationSchema = yup.object({
    emailID: STRING_VALIDATOR,
    password: PASSWORD_VALIDATOR,
  });

  useEffect(() => {
    document.title = 'Sign In | Digital Filing';
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialState}
      onSubmit={(values, actions) => {
        setIsLoading(true);
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}Account/login`, {
            username: values.emailID,
            password: values.password,
          })
          .then((res) => {
            setIsLoading(false);
            const parsedToken = PARSE_JWT(res.data.data.accessToken);
            if (parsedToken.Permission.length == 0) {
              WarningAlert('You do not have the permission to continue.\n For further action, please contact the administrator!', 'Action Required');
              return;
            }
            localStorage.setItem(USER_AUTH_TOKEN_KEY, JSON.stringify(res.data.data.accessToken));
            localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(res.data.data.refreshToken));
            dispatch(userSignIn(parsedToken, navigate));
          })
          .catch((err) => {
            console.log(err, 'err');
            if (err.response && err.response.status == ERROR_CODES.UNAUTHORIZED) {
              WarningAlert(err.response.data.message);
            } else if (err.message) {
              WarningAlert(err.message);
            }
            setIsLoading(false);
          });
      }}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <Form
          noValidate={true}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Flex dir="row">
            <Box
              display={{ base: 'none', sm: 'none', md: 'block', lg: 'block' }}
              border={1}
              borderColor={'green'}
              borderWidth={1}
              h={'100vh'}
              w={'50%'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box className="login">
                <Box className="left-content"></Box>
              </Box>
            </Box>
            <Box
              border={1}
              padding={{ base: '30px', sm: '30px', md: '30px', lg: '30px' }}
              borderWidth={1}
              h={'100vh'}
              borderColor={'#F0AC00'}
              w={{ base: '100%', sm: '100%', md: '50%', lg: '50%' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box w={{ base: '100%', sm: '100%', md: '50%', lg: '50%' }}>
                <Box position={'absolute'} right={'0px'} top={'10px'} fontSize={'14px'}></Box>
                <Flex justifyContent={'center'} w={'200px'} margin={'0px auto 20px'}>
                  <Image src="./images/DF_LOGO.png" />
                </Flex>

                <Stack spacing={6} marginBottom={'30px'}>
                  {/* <Heading variant={"h2"}>Sign In</Heading> */}
                  <Heading variant={'h5'}>Enter your username and password to Sign In</Heading>
                </Stack>
                <FormControl gap="3">
                  <TextInput
                    type="text"
                    isRequired={true}
                    name="emailID"
                    value={values.emailID}
                    label={{
                      text: 'Username',
                      colors: errors.emailID ? 'red !important' : '',
                      variant: errors.emailID ? 'errored' : '',
                    }}
                    onChange={(e) => handleChange(e)}
                    placeholder={''}
                    variant={errors.password ? 'errored' : 'flushed'}
                    isReadOnly={isLoading}
                    paddingLeft={'0px'}
                  />

                  <TextInput
                    type="password"
                    isRequired={true}
                    name="password"
                    value={values.password}
                    label={{
                      text: 'Password',
                      colors: errors.password ? 'red !important' : '',
                      variant: errors.password ? 'errored' : '',
                    }}
                    onChange={(e) => handleChange(e)}
                    placeholder={''}
                    paddingLeft={'0px'}
                    variant={errors.password ? 'errored' : 'flushed'}
                    isReadOnly={isLoading}
                    icon={{
                      visible: true,
                    }}
                  />
                </FormControl>
                <Box>
                  <Box
                    onClick={() => navigate('/forgot-password')}
                    fontSize={'13px'}
                    cursor={'pointer'}
                    color={colors.primaryColor}
                    display={'flex'}
                    justifyContent={'flex-end'}
                    textDecoration={'none'}
                    _hover={{ textDecoration: 'none' }}
                  >
                    Forget Password
                  </Box>
                </Box>
                <Box>
                  <WrapItem>
                    <Button variant={'primary'} width={'100%'} marginTop={'20px'} type="submit">
                      {isLoading ? <CircularProgress isIndeterminate size="24px" color={colors.white} /> : 'Sign In'}
                    </Button>
                  </WrapItem>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
