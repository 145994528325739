import React, { useEffect } from "react";
import Pagination from "../../theme/components/pagination/pagination";
import {
  Box,
  Button,
  Collapse,
  Container,
  Heading,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import { GoFilter } from "react-icons/go";
import TextInput from "../../theme/components/input/text-input";
import SelectInput from "../../theme/components/input/select-input";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { MENU_NAMES, MODULE_ENUMS, PAGE_SIZE } from "../../core/utils/constants";
import { colors } from "../../theme/styles/colors";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTableHeightFix from "../../core/hooks/useTableHeightFix";
import { useNavigate } from "react-router-dom";
import {
  convertDateFormat,
  createFilterPayload,
  getLastActiveFilter,
  getAll,
  getFilterCount,
  handleFilterChange,
  handleResetState,
  handleActiveFilter,
  createFilterState,
} from "../../core/utils/common-function";
import DocumentStatusLogs from "./document-status-logs";
import FileRemarkLogs from "./file-remarks-logs";
import Enquiries from "./enquiries";
import NewImmigartionFiles from "./new-immigration-files";
import api from "../../core/api";

const DailyRoutine = (props) => {
  const { moduleId} = props;
  const selector = useSelector((_) => _);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeFilter, setActiveFilter] = useState(0);
  const [isToDate, setIsToDate] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [tableRowData, setTableRowData] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [fileDocument, setFileDocument] = useState([]);
  const [minToDate, setMinToDate] = useState(
    convertDateFormat(new Date(), "yyyy-mm-dd")
  );
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
    branchId: selector.branch.userBranchId,
  });
  const [initialState, setInitialState] = useState({
    name: "",
    isActive: false,
    caseStatus: null,
    fileDocument: null,
  });
  const [filterState, setFilterState] = useState({
    fromDate: "",
    toDate: "",
  });
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const [show, setShow] = useState(false);
  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };
  const handleToggle = () => {
    setShow(!show);
  };
  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleActiveFilter("delete", moduleId)
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
  };

  const lastActiveFilter = async ()=>{
    const lastFilter = await handleActiveFilter('get', moduleId);
    if (lastFilter) {
      const filledFilter = JSON.parse(lastFilter.data.data);
      if (filledFilter) {
        setAPIPayload({
          ...apiPayload,
          filter: filledFilter,
          pageSize: PAGE_SIZE,
          pageIndex: 1,
        });
        createFilterState(filledFilter, setFilterState, setActiveFilter);
      }
    }
  };

  useEffect(()=>{
    lastActiveFilter()
  },[])

  const getAllPromises = () => {
    const CaseStatus = getAll("CaseStatus", payload);
    const FileDocument1 = getAll("FileDocument", payload);
    Promise.all([CaseStatus, FileDocument1])
      .then((res) => {
        const [caseStatus1, fileDocument1] = res;

        const CaseStatus = caseStatus1.data.data.map((caseStatus1) => ({
          text: caseStatus1.name,
          value: caseStatus1.caseStatusId,
        }));
        const FileDocument = fileDocument1.data.data.map((fileDocument1) => ({
          text: fileDocument1.name,
          value: fileDocument1.fileDocumentId,
          name: fileDocument1.name,
        }));
        setCaseStatus(CaseStatus);
        setFileDocument(FileDocument);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    document.title = "Daily Routine | Digital Filing";
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getAllPromises();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId]);

  return (
    <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
      <Box>
        <BreadcrumbBox
          route={GET_BREADCRUMB_ROUTES(MENU_NAMES.DAILY_ROUTINE, true)}
        />
      </Box>
      <Box
        color={colors.white}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={"20px"}
      >
        <Box>
          <Heading variant={"h1"}>Daily Routine</Heading>
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={"20px"}>
          <Button onClick={handleToggle} variant={"secondary"}>
            <GoFilter fontSize={"20px"} />
            <Text marginLeft={"5px"} className="btnText ">
              Filter
            </Text>
            <Box
              position={"absolute"}
              top={"-19px"}
              right={"-1"}
              backgroundColor={colors.primaryColor}
              color={colors.white}
              borderRadius={"16px"}
              padding={"0px"}
              height={"30px"}
              width={"30px"}
              lineHeight={"30px"}
            >
              {activeFilter}
            </Box>
          </Button>
        </Box>
      </Box>
      <Box
        backgroundColor={colors.white}
        boxShadow={"0px 0px 35px 0px #F5F5F5"}
        borderRadius={"10px"}
        marginBottom={"20px"}
      >
        <Collapse in={show}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const payloadArr = createFilterPayload(filterState);
              const filterSum = getFilterCount(filterState);
              setActiveFilter(filterSum);
              setAPIPayload({
                ...apiPayload,
                filter: payloadArr,
                pageSize: PAGE_SIZE,
                pageIndex: 1,
              });
              const jsonString = JSON.stringify(payloadArr);
              handleActiveFilter("post",'',{moduleId:moduleId, StateJson:jsonString  })
            }}
          >
            <SimpleGrid
              columns={{ base: "1", sm: "1", md: "2", lg: "4" }}
              spacingX="40px"
              padding={"20px"}
              paddingBottom={"0px"}
            >
              <TextInput
                type="date"
                name="fromDate"
                value={filterState.fromDate?.value}
                onChange={(e) => {
                  let D = new Date(e.target.value).setDate(
                    new Date(e.target.value).getDate() + 1
                  );
                  setMinToDate(convertDateFormat(D, "yyyy-mm-dd"));
                  handleFilterChange(e, filterState, setFilterState, "From");
                  if (e.target.value != "") {
                    setIsToDate(false);
                  }
                }}
                placeholder={""}
                variant="flushed"
                label={{ text: "From" }}
              />
              <TextInput
                type="date"
                isDisabled={isToDate}
                name="toDate"
                minVal={minToDate}
                value={filterState.toDate?.value}
                onChange={(e) =>
                  handleFilterChange(e, filterState, setFilterState,  "To" )
                }
                placeholder={""}
                variant="flushed"
                label={{ text: "To" }}
              />
              <SelectInput
                formControl={{ id: "caseStatusId", variant: "floating" }}
                isRequired={false}
                isReadOnly={false}
                value={filterState.caseStatusId?.value}
                name="caseStatusId"
                onChange={(e) =>
                  handleFilterChange(e, filterState, setFilterState,  "Case Status")
                }
                label={{
                  text: "Case Status",
                }}
                options={caseStatus}
              />
              <SelectInput
                formControl={{ id: "fileStatus", variant: "floating" }}
                isRequired={false}
                isReadOnly={false}
                value={filterState.fileDocumentId?.value}
                name="fileDocumentId"
                onChange={(e) =>
                  handleFilterChange(e, filterState, setFilterState,  "File Status")
                }
                label={{
                  text: "File Status",
                }}
                options={fileDocument}
              />
            </SimpleGrid>
            <Box
              display={"flex"}
              justifyContent={"end"}
              paddingRight={"20px"}
              paddingBottom={"20px"}
            >
              <Button
                fontSize={{
                  base: "12px",
                  sm: "13px",
                  md: "14px",
                  lg: "14px",
                }}
                variant={"primary"}
                type="submit"
              >
                Search
              </Button>
              <Button
                fontSize={{
                  base: "12px",
                  sm: "13px",
                  md: "14px",
                  lg: "14px",
                }}
                variant={"warning"}
                marginLeft={"10px"}
                isDisabled={activeFilter > 0 ? false : true}
                onClick={resetFilterState}
              >
                Reset
              </Button>
            </Box>
          </form>
        </Collapse>
      </Box>

      <Tabs
        defaultIndex={tabIndex}
        onChange={(index) => setTabIndex(index)}
        variant={"colorful"}
        size={"md"}
      >
        <TabList borderBottom={"1px solid #e9e9e9"} marginBottom={"20px"}>
          <Tab fontSize={{ base: "11px", sm: "12px", md: "14px", lg: "14px" }}>
            Enquiries
          </Tab>
          <Tab fontSize={{ base: "11px", sm: "12px", md: "14px", lg: "14px" }}>
            New Immigration Files
          </Tab>
          <Tab fontSize={{ base: "11px", sm: "12px", md: "14px", lg: "14px" }}>
            Document Status Logs
          </Tab>
          <Tab fontSize={{ base: "11px", sm: "12px", md: "14px", lg: "14px" }}>
            File Remark Logs
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel borderRadius={"10px"} padding={"0px"}>
            <Enquiries
              apiPayload={apiPayload}
              setAPIPayload={setAPIPayload}
              tabIndex={tabIndex}
              permission={props}
            />
          </TabPanel>
          <TabPanel borderRadius={"10px"} padding={"0px"}>
            <NewImmigartionFiles
              apiPayload={apiPayload}
              setAPIPayload={setAPIPayload}
              tabIndex={tabIndex}
              permission={props}
            />
          </TabPanel>
          <TabPanel padding={"0px"}>
            <DocumentStatusLogs
              apiPayload={apiPayload}
              setAPIPayload={setAPIPayload}
              tabIndex={tabIndex}
              permission={props}
            />
          </TabPanel>
          <TabPanel padding={"0px"}>
            <FileRemarkLogs
              apiPayload={apiPayload}
              setAPIPayload={setAPIPayload}
              tabIndex={tabIndex}
              permission={props}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default DailyRoutine;
